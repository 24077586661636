import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes, FaSignInAlt, FaUserPlus, FaFacebookF, FaInstagram, FaTwitter, FaGithub } from 'react-icons/fa';
import logo from '../../assets/logo.png';

const name = process.env.REACT_APP_SITE_NAME;
const wa = process.env.REACT_APP_WHATSAPP;
const fb = process.env.REACT_APP_FACEBOOK;
const ig = process.env.REACT_APP_INSTAGRAM;
const tw = process.env.REACT_APP_TWITTER;
const gh = process.env.REACT_APP_GITHUB;

const Header = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isMenuOpen && !event.target.closest('nav')) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]);

    return (
        <nav className='container mx-auto'>
            <div className='hidden md:grid px-3 md:px-0 py-2 grid-cols-2 md:grid-cols-3'>
                <div className='flex justify-start items-center space-x-2'>
                    <Link to={fb} className="flex items-center gap-2 hover:text-blue-500 transition-all duration-300">
                        <FaFacebookF className="w-5 h-5" />
                    </Link>
                    <Link to={ig} className="flex items-center gap-2 hover:text-blue-500 transition-all duration-300">
                        <FaInstagram className="w-5 h-5" />
                    </Link>
                    <Link to={tw} className="flex items-center gap-2 hover:text-blue-500 transition-all duration-300">
                        <FaTwitter className="w-5 h-5" />
                    </Link>
                    <Link to={gh} className="flex items-center gap-2 hover:text-blue-500 transition-all duration-300">
                        <FaGithub className="w-5 h-5" />
                    </Link>
                </div>
                <div className='text-center'>
                    <p className='text-sm text-gray-500'>Free shipping this week order over - Rp. 5,000k</p>
                </div>
                <div className='text-right'>
                    <Link to={wa} className='text-sm text-gray-500 hover:text-blue-500 transition-all duration-300'>{wa.replace('https://wa.me/', '').replace('62', '0')}</Link>
                </div>
            </div>
            <hr />
            <div className='px-3 md:px-0 py-2 grid grid-cols-2 md:grid-cols-3'>
                <div className='hidden md:flex justify-start items-center space-x-4 uppercase text-lg font-semibold'>
                    {['home', 'tentang', 'kontak'].map((section) => (
                        <Link key={section} to={`/${section}`} className='hover:text-blue-500 transition-all ease-in duration-300'>
                            {section.charAt(0).toUpperCase() + section.slice(1)}
                        </Link>
                    ))}
                </div>
                <Link to='/' className='text-xl font-bold uppercase flex md:justify-center items-center gap-2'>
                    <img src={logo} alt={name} className='w-16 h-12 object-contain' />
                    <p className='block md:hidden'>
                        {name.split(' ').map((word, index) => (
                            <span key={index} className={index === 1 ? "ml-2 text-blue-500" : ""}>
                                {word}
                            </span>
                        ))}
                    </p>
                </Link>
                <div className='hidden md:flex justify-end items-center space-x-3'>
                    <Link to="/login" className='flex justify-center items-center px-3 py-2 hover:text-blue-500 transition-all ease-in duration-300'>
                        <FaSignInAlt className='w-5 h-5 mr-2' />
                        Login
                    </Link>
                    <Link to="/signup" className='flex justify-center items-center px-3 py-2 hover:text-blue-500 transition-all ease-in duration-300'>
                        <FaUserPlus className='w-5 h-5 mr-2' />
                        Sign Up
                    </Link>
                </div>
            </div>
            <div className='md:hidden absolute top-5 right-5 z-10'>
                <button onClick={toggleMenu} className=' text-blue-500 hover:text-blue-700 focus:outline-none outline-1'>
                    {isMenuOpen ? <FaTimes className='w-6 h-6' /> : <FaBars className='w-6 h-6' />}
                </button>
            </div>
            {isMenuOpen && (
                <div className='absolute top-0 left-0 w-full bg-gray-100 transition-all duration-300'>
                    <div className='md:hidden transition-all duration-300 flex flex-col items-center'>
                        <div className='pt-2 pb-3 space-y-1 w-full text-center'>
                            {['home', 'tentang', 'kontak'].map((section) => (
                                <Link key={section} to={`/${section}`} className='py-2 block text-base font-medium text-gray-700 hover:text-blue-500 hover:bg-blue-500 hover:bg-opacity-10 transition-all ease-in duration-300'>
                                    {section.charAt(0).toUpperCase() + section.slice(1)}
                                </Link>
                            ))}
                            <Link to="/login" className='py-2 flex justify-center text-base font-medium text-gray-700 hover:text-blue-500 hover:bg-blue-500 hover:bg-opacity-10 transition-all ease-in duration-300'>
                                <FaSignInAlt className='w-5 h-5 mr-2' />
                                Login
                            </Link>
                            <Link to="/signup" className='py-2 flex justify-center text-base font-medium text-gray-700 hover:text-blue-500 hover:bg-blue-500 hover:bg-opacity-10 transition-all ease-in duration-300'>
                                <FaUserPlus className='w-5 h-5 mr-2' />
                                Sign Up
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Header;