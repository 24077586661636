import React from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";
import Home from "../site/Home";

export default function Main() {
    return (
        <React.Fragment>
            <div className="flex flex-col min-h-screen text-gray-800 dark:text-gray-300 overflow-x-hidden font-poppins">
                <Header />
                <main className="flex-grow">
                    <Home />
                </main>
                <Footer />
            </div>
        </React.Fragment>
    );
}
