import React from 'react'
import { FaEnvelope } from 'react-icons/fa'
import { motion, useAnimation } from 'framer-motion'
import { useInView } from 'react-intersection-observer'

const Subscribe = () => {
    const controls = useAnimation()
    const [ref, inView] = useInView({ triggerOnce: true })

    React.useEffect(() => {
        if (inView) {
            controls.start('visible')
        }
    }, [controls, inView])

    const textVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.5 } }
    }

    return (
        <motion.section
            ref={ref}
            initial="hidden"
            animate={controls}
            variants={textVariants}
            className="bg-gray-100 px-4 flex justify-center items-center h-auto py-12"
        >
            <div className="container mx-auto px-4">
                <div className="flex flex-col items-center text-center mb-4">
                    <div className="w-full md:w-3/4 lg:w-1/2">
                        <motion.h6
                            variants={textVariants}
                            className="mb-3 font-semibold text-lg md:text-xl"
                        >
                            Berita Kami
                        </motion.h6>
                        <motion.h3
                            variants={textVariants}
                            className="font-semibold text-lg sm:text-xl md:text-2xl text-blue-500"
                        >
                            Daftar Berita Kami untuk Mendapatkan Update Terbaru Produk Kami
                        </motion.h3>
                        <motion.p
                            variants={textVariants}
                            className="text-gray-500 text-sm"
                        >
                            Dapatkan 20% Diskon pada pesanan pertama Anda hanya dengan mendaftar ke surat berita Kami
                        </motion.p>
                        <form action="#" className="flex flex-col sm:flex-row justify-center items-center mt-4">
                            <div className="block md:flex items-center">
                                <div className="flex justify-center items-center">
                                    <label htmlFor="email" className="bg-blue-500 hover:bg-blue-400 text-white p-3 h-full flex items-center">
                                        <FaEnvelope />
                                    </label>
                                </div>
                                <motion.input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="youremail@chickglasses.id"
                                    className="form-control-lg form-control rounded-none h-full py-2 px-4"
                                    variants={textVariants}
                                    initial="hidden"
                                    animate={controls}
                                />
                            </div>
                            <div className="flex justify-center items-center">
                                <button type="submit" className="bg-blue-500 hover:bg-blue-400 text-white p-2 h-full">
                                    Berlangganan
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </motion.section>
    )
}

export default Subscribe