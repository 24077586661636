import React from 'react';
import Hero from '../components/Hero';
import Services from '../components/Services';
import Kategori from '../components/Kategori';
import Penawaran from '../components/Penawaran';
import Follow from '../components/Follow';
import Testimoni from '../components/Testimoni';
import Blog from '../components/Blog';
import Subscribe from '../components/Subscribe';

const Home = () => {
    return (
        <React.Fragment>
            <Hero />
            <Services />
            <Kategori />
            <Penawaran />
            <Follow />
            <Testimoni />
            <Blog />
            <Subscribe />
        </React.Fragment>
    );
};

export default Home;