import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import kategori1 from '../../assets/kategori1.png';
import kategori2 from '../../assets/kategori2.png';
import kategori3 from '../../assets/kategori3.png';

export default function Kategori() {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: false });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    const variants = {
        visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
        hidden: { opacity: 0, x: -50 }
    };

    return (
        <div className="mb-4">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
                    <motion.div
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={variants}
                        className="bg-gray-100 rounded-3 relative h-96 xl:min-h-screen p-4"
                    >
                        <motion.div
                            initial="hidden"
                            animate={controls}
                            variants={variants}
                            className="relative z-10 max-w-72 lg:max-w-96"
                        >
                            <div className='flex'>
                                <p className='text-sm text-gray-500 bg-gray-50 rounded-full px-4 py-2'><span className='text-blue-500 font-semibold'>2500+</span> Items</p>
                            </div>
                            <h3 className="text-2xl md:text-3xl font-semibold">Kacamata Wanita</h3>
                            <p>Beragam kacamata wanita, dari klasik hingga modern. Pilihan frame dan lensa berkualitas untuk gaya dan kenyamanan maksimal.</p>
                        </motion.div>
                        <motion.div
                            initial="hidden"
                            animate={controls}
                            variants={variants}
                            className="pt-20 sm:pt-0 absolute inset-0 flex justify-end z-0 overflow-hidden"
                        >
                            <img
                                className="object-cover w-auto md:h-96 xl:h-full transform translate-x-10"
                                src={kategori1}
                                alt="Kacamata Wanita"
                            />
                        </motion.div>
                    </motion.div>

                    <div className="flex flex-col justify-between space-y-4">
                        <motion.div
                            ref={ref}
                            initial="hidden"
                            animate={controls}
                            variants={variants}
                            className="bg-gray-100 rounded-3 relative h-96 xl:h-1/2 p-4"
                        >
                            <motion.div
                                initial="hidden"
                                animate={controls}
                                variants={variants}
                                className="relative z-10 max-w-72 lg:max-w-96"
                            >
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 bg-gray-50 rounded-full px-4 py-2'><span className='text-blue-500 font-semibold'>1500+</span> Items</p>
                                </div>
                                <h3 className="text-2xl md:text-3xl font-semibold">Kacamata Pria</h3>
                                <p>Berbagai pilihan kacamata pria yang stylish dan fungsional. Cocok untuk segala aktivitas dengan lensa pelindung UV.</p>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                animate={controls}
                                variants={variants}
                                className="pt-32 sm:pt-0 pr-10 absolute inset-0 flex justify-end z-0 overflow-hidden"
                            >
                                <img
                                    className="object-cover w-auto h-full transform translate-x-10"
                                    src={kategori2}
                                    alt="Kacamata Laki"
                                />
                            </motion.div>
                        </motion.div>

                        <motion.div
                            ref={ref}
                            initial="hidden"
                            animate={controls}
                            variants={variants}
                            className="bg-gray-100 rounded-3 relative h-96 xl:h-1/2 p-4"
                        >
                            <motion.div
                                initial="hidden"
                                animate={controls}
                                variants={variants}
                                className="relative z-10 max-w-72 lg:max-w-96"
                            >
                                <div className='flex'>
                                    <p className='text-sm text-gray-500 bg-gray-50 rounded-full px-4 py-2'><span className='text-blue-500 font-semibold'>800+</span> Items</p>
                                </div>
                                <h3 className="text-2xl md:text-3xl font-semibold">Softlens</h3>
                                <p>Softlens nyaman dan aman dalam berbagai warna. Pilihan harian, bulanan, dan tahunan untuk koreksi penglihatan dan gaya.</p>
                            </motion.div>
                            <motion.div
                                initial="hidden"
                                animate={controls}
                                variants={variants}
                                className="pt-32 sm:pt-0 pr-10 absolute inset-0 flex justify-end z-0 overflow-hidden"
                            >
                                <img
                                    className="object-cover w-auto h-full transform translate-x-10"
                                    src={kategori3}
                                    alt="Softlens"
                                />
                            </motion.div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </div>
    );
}