import React from "react";
import penawaran from '../../assets/penawaran.png';
import { FaArrowRight } from "react-icons/fa";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Penawaran() {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: false });

    React.useEffect(() => {
        if (inView) {
            controls.start("visible");
        } else {
            controls.start("hidden");
        }
    }, [controls, inView]);

    const textVariants = {
        hidden: { opacity: 0, x: 100 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.5 } }
    };

    const imageVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.5 } }
    };

    return (
        <div className="bg-gray-100">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 h-full xl:min-h-screen items-center">
                    <motion.div
                        className="flex justify-center w-full"
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={imageVariants}
                    >
                        <img
                            className="object-cover w-auto sm:h-72 md:h-96 xl:min-h-screen"
                            src={penawaran}
                            alt="Home"
                        />
                    </motion.div>
                    <motion.div
                        className="w-full p-4 text-center md:text-left"
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={textVariants}
                    >
                        <h3 className="mb-5 text-2xl font-semibold text-blue-500">Penawaran Waktu Terbatas</h3>
                        <h3 className="md:text-2xl lg:text-4xl font-bold">20% Off Semua Produk Favorit - Waktu Terbatas!</h3>
                        <p className="mt-3">Dengan membeli produk kami membuat kami menjadi memiliki banyak uang karena kami menawarkan produk yang sangat berkualitas dan keren keren juga</p>
                        <div className="flex justify-center md:justify-start">
                            <a
                                href="/shop"
                                className="no-underline flex items-center bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 mt-4 inline-block"
                            >
                                Belanja Sekarang <FaArrowRight className="ml-2" />
                            </a>
                        </div>
                    </motion.div>
                </div>
            </div>
        </div>
    )
}