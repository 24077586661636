import React from "react";
import { Link } from 'react-router-dom';
import { FaWhatsapp, FaInstagram, FaGithub, FaFacebookF, FaTwitter } from 'react-icons/fa';
import logo from '../../assets/logo.png';

const whatsapp = process.env.REACT_APP_WHATSAPP;
const instagram = process.env.REACT_APP_INSTAGRAM;
const github = process.env.REACT_APP_GITHUB;
const fb = process.env.REACT_APP_FACEBOOK;
const tw = process.env.REACT_APP_TWITTER;

const name = process.env.REACT_APP_SITE_NAME;
const mail = process.env.REACT_APP_MAIL;
const phone = process.env.REACT_APP_PHONE;

const Footer = () => {
    const menu = {
        Navigasi: [
            { name: 'Home', link: '/' },
            { name: 'Tentang', link: '/tentang' },
            { name: 'Kontak', link: '/kontak' },
        ],
        Informasi: [
            { name: 'Kebijakan Privasi', link: '/privacy-policy' },
            { name: 'Syarat & Ketentuan', link: '/terms-condition' },
            { name: 'FAQ', link: '/faq' },
        ],
        Kontak: [
            { name: 'Email', link: `mailto:${mail}` },
            { name: 'Telepon', link: `tel:${phone}` },
            { name: 'Alamat', link: '/kontak' },
        ],
    };
    return (
        <footer className='bg-gray-200'>
            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-4 gap-4">
                    <div className="col-span-1 px-4">
                        <Link to='/' className='text-xl font-bold uppercase flex items-center gap-2'>
                            <img src={logo} alt={name} className='w-16 h-12 object-contain' />
                            {name.split(' ').map((word, index) => (
                                <span key={index} className={index === 1 ? "text-blue-500" : ""}>
                                    {word}
                                </span>
                            ))}
                        </Link>
                        <p>Dengan membeli produk di CHIC GLASSES, anda setuju dengan <Link to={'/terms-condition'} className="text-blue-500 hover:underline">Terms & Condition</Link></p>
                        <div className="flex gap-2 mt-2">
                            <Link to={whatsapp} className=" border rounded-full p-2 border-blue-500 text-blue-500 hover:underline hover:bg-blue-500 hover:text-white transition-all duration-300">
                                <FaWhatsapp className="w-5 h-5" />
                            </Link>
                            <Link to={instagram} className=" border rounded-full p-2 border-blue-500 text-blue-500 hover:underline hover:bg-blue-500 hover:text-white transition-all duration-300">
                                <FaInstagram className="w-5 h-5" />
                            </Link>
                            <Link to={github} className=" border rounded-full p-2 border-blue-500 text-blue-500 hover:underline hover:bg-blue-500 hover:text-white transition-all duration-300">
                                <FaGithub className="w-5 h-5" />
                            </Link>
                            <Link to={fb} className=" border rounded-full p-2 border-blue-500 text-blue-500 hover:underline hover:bg-blue-500 hover:text-white transition-all duration-300">
                                <FaFacebookF className="w-5 h-5" />
                            </Link>
                            <Link to={tw} className=" border rounded-full p-2 border-blue-500 text-blue-500 hover:underline hover:bg-blue-500 hover:text-white transition-all duration-300">
                                <FaTwitter className="w-5 h-5" />
                            </Link>
                        </div>
                    </div>
                    <div className="col-span-1 lg:col-span-2">
                        <div className="grid grid-cols-2 md:grid-cols-3">
                            {Object.keys(menu).map((key, index) => (
                                <div key={index} className="col-span-1 px-4">
                                    <h6 className="text-md font-bold uppercase text-blue-500">{key}</h6>
                                    <ul>
                                        {menu[key].map((item, idx) => (
                                            <li key={idx} className="py-2">
                                                <Link to={item.link} className="text-gray-700 hover:underline">{item.name}</Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <hr />
                <div className="text-sm text-gray-500">
                    <p className="py-4">Developed & Designed by <strong className="text-blue-500 uppercase"><Link to={instagram} className="hover:underline">{name}</Link></strong> || &copy; 2024 All Rights Reserved</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;