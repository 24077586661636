import React, { useEffect } from "react";
import * as pathImage from '../../assets/follow';
import { FaInstagram } from 'react-icons/fa';
import { Link } from "react-router-dom";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

const instagram = process.env.REACT_APP_INSTAGRAM;

const Follow = () => {
    const images = Object.values(pathImage);
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: false });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        } else {
            controls.start("hidden");
        }
    }, [controls, inView]);

    const textVariants = {
        hidden: { opacity: 0, y: 50 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
    };

    const imageVariants = {
        hidden: { opacity: 0, x: 100 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.5 } }
    };

    return (
        <div className="container mx-auto py-4">
            <div className="flex items-center min-h-screen">
                <div className="w-full">
                    <motion.div
                        className="text-center mb-4"
                        ref={ref}
                        initial="hidden"
                        animate={controls}
                        variants={textVariants}
                    >
                        <h6 className="mb-3 font-semibold text-lg md:text-xl">Ikuti Kami</h6>
                        <h3 className="mb-3 font-semibold text-lg sm:text-xl md:text-2xl text-blue-500">Ikuti Kami di Instagram</h3>
                    </motion.div>
                    <div className="flex justify-evenly flex-wrap xl:flex-nowrap gap-4">
                        {images.map((src, index) => (
                            <motion.div
                                key={index}
                                className="relative group w-1/3 xl:w-full"
                                initial="hidden"
                                animate={controls}
                                variants={imageVariants}
                            >
                                <Link
                                    to={instagram}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Follow Us"
                                >
                                    <img
                                        className="object-cover w-full h-64 group-hover:brightness-50 transition-all duration-300"
                                        src={src}
                                        alt={`Follow us ${index + 1}`}
                                    />
                                    <div className="absolute inset-0 flex items-center justify-center ">
                                        <FaInstagram className="text-white text-4xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                                    </div>
                                </Link>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Follow;