import React, { useEffect } from 'react';
import hero from '../../assets/hero.png';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

export default function Hero() {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: false });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        } else {
            controls.start('hidden');
        }
    }, [controls, inView]);

    return (
        <div className='bg-gray-100 min-h-screen justify-center items-center'>
            <div className='container mx-auto'>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5'>
                    <div className='col-span-1 lg:col-span-3'>
                        <div className='flex flex-col justify-center items-center lg:min-h-screen'>
                            <div className='px-4 text-center md:text-start py-4' ref={ref}>
                                <motion.h1 
                                    className='text-2xl md:text-4xl text-blue-500 font-bold'
                                    initial="hidden"
                                    animate={controls}
                                    variants={{
                                        hidden: { opacity: 0, x: -50 },
                                        visible: { opacity: 1, x: 0 }
                                    }}
                                    transition={{ duration: 0.5 }}
                                >
                                    Step into Style
                                </motion.h1>
                                <motion.h1 
                                    className='text-2xl md:text-4xl lg:text-6xl font-bold'
                                    initial="hidden"
                                    animate={controls}
                                    variants={{
                                        hidden: { opacity: 0, x: -50 },
                                        visible: { opacity: 1, x: 0 }
                                    }}
                                    transition={{ duration: 0.5, delay: 0.2 }}
                                >
                                    Your Ultimate Fashion Destination
                                </motion.h1>
                                <motion.p 
                                    className='text-gray-500 text-lg py-4'
                                    initial="hidden"
                                    animate={controls}
                                    variants={{
                                        hidden: { opacity: 0, x: -50 },
                                        visible: { opacity: 1, x: 0 }
                                    }}
                                    transition={{ duration: 0.5, delay: 0.4 }}
                                >
                                    Start your journey with us
                                </motion.p>
                                <motion.div 
                                    className='flex items-center gap-2 justify-center md:justify-start'
                                    initial="hidden"
                                    animate={controls}
                                    variants={{
                                        hidden: { opacity: 0, x: -50 },
                                        visible: { opacity: 1, x: 0 }
                                    }}
                                    transition={{ duration: 0.5, delay: 0.6 }}
                                >
                                    <Link to='/shop' className='bg-blue-500 hover:bg-blue-600 transition-all duration-300 text-white px-4 py-2 flex items-center gap-2'>Shop Now <FaArrowRight /></Link>
                                </motion.div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-1 lg:col-span-2 max-h-screen'>
                        <motion.img 
                            src={hero} 
                            alt='hero' 
                            className='w-full h-full object-contain'
                            initial="hidden"
                            animate={controls}
                            variants={{
                                hidden: { opacity: 0, x: +50 },
                                visible: { opacity: 1, x: 0 }
                            }}
                            transition={{ duration: 1 }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};