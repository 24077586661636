import React, { useState, useEffect, useRef } from "react";
import { FaStar, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import devi from '../../assets/testimoni/testimoni-devi.png';
import ghea from '../../assets/testimoni/testimoni-ghea.png';
import iqbal from '../../assets/testimoni/testimoni-iqbal.png';
import lalisa from '../../assets/testimoni/testimoni-lalisa2.png';
import jennie from '../../assets/testimoni/testimoni-jennie.png';
import verrell from '../../assets/testimoni/testimoni-verrel.png';

const testimonials = [
    {
        name: "Devi Yuliana",
        role: "Seleb Kampus",
        image: devi,
        rating: 5,
        feedback:
            "Pengalaman berbelanja di Chic Glasses sangat memuaskan. Kualitas produk dan pelayanan yang diberikan sangat luar biasa. Kacamata yang saya beli tidak hanya memenuhi kebutuhan visual saya, tetapi juga memberikan kesan stylish yang saya sukai. Saya akan merekomendasikan Chic Glasses kepada teman dan keluarga saya.",
    },
    {
        name: "Ghea Youbi",
        role: "Penyanyi",
        image: ghea,
        rating: 5,
        feedback:
            "Berbelanja di Chic Glasses adalah pengalaman yang luar biasa. Saya sangat puas dengan kualitas produk dan pelayanan yang diberikan. Kacamata yang saya beli tidak hanya memenuhi kebutuhan saya secara visual, tetapi juga memberikan saya kesan stylish yang saya sukai. Saya akan merekomendasikan Chic Glasses kepada teman dan keluarga saya yang mencari kacamata berkualitas tinggi.",
    },
    {
        name: "Iqbal Ramadhan",
        role: "Aktor & Penyanyi",
        image: iqbal,
        rating: 5,
        feedback:
            "Chic Glasses memberikan pengalaman belanja yang luar biasa. Produk berkualitas tinggi dan pelayanan yang memuaskan membuat saya sangat puas. Kacamata yang saya beli tidak hanya memenuhi kebutuhan visual saya, tetapi juga memberikan kesan stylish yang saya sukai. Saya akan merekomendasikan Chic Glasses kepada teman dan keluarga saya.",
    },
    {
        name: "Lalisa Manoban",
        role: "Rapper, Dancer, Penyanyi",
        image: lalisa,
        rating: 5,
        feedback:
            "Berbelanja di Chic Glasses adalah pengalaman yang luar biasa. Saya sangat puas dengan kualitas produk dan pelayanan yang diberikan. Kacamata yang saya beli tidak hanya memenuhi kebutuhan visual saya, tetapi juga memberikan kesan stylish yang saya sukai. Saya akan merekomendasikan Chic Glasses kepada teman dan keluarga saya yang mencari kacamata berkualitas tinggi.",
    },
    {
        name: "Jennie Ruby Jane",
        role: "Rapper, Dancer, Penyanyi",
        image: jennie,
        rating: 5,
        feedback:
            "Pengalaman berbelanja di Chic Glasses sangat memuaskan. Kualitas produk dan pelayanan yang diberikan sangat luar biasa. Kacamata yang saya beli tidak hanya memenuhi kebutuhan visual saya, tetapi juga memberikan kesan stylish yang saya sukai. Saya akan merekomendasikan Chic Glasses kepada teman dan keluarga saya.",
    },
    {
        name: "Verrell Bramasta",
        role: "Selebriti & Aktor",
        image: verrell,
        rating: 5,
        feedback:
            "Pengalaman berbelanja di Chic Glasses sangat memuaskan. Kualitas produk dan pelayanan yang diberikan sangat luar biasa. Kacamata yang saya beli tidak hanya memenuhi kebutuhan visual saya, tetapi juga memberikan kesan stylish yang saya sukai. Saya akan merekomendasikan Chic Glasses kepada teman dan keluarga saya.",
    },
];

const TestimonialCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const intervalRef = useRef(null);
    const controls = useAnimation();
    const { ref, inView } = useInView({ triggerOnce: true });

    const startAutoplay = () => {
        intervalRef.current = setInterval(() => {
            setFade(false);
            setTimeout(() => {
                setCurrentIndex((prevIndex) =>
                    prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
                );
                setFade(true);
            }, 1000);
        }, 5000);
    };

    const resetAutoplay = () => {
        clearInterval(intervalRef.current);
        startAutoplay();
    };

    useEffect(() => {
        startAutoplay();
        return () => clearInterval(intervalRef.current);
    }, []);

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const handlePrev = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex(
                currentIndex === 0 ? testimonials.length - 1 : currentIndex - 1
            );
            setFade(true);
            resetAutoplay();
        }, 1000);
    };

    const handleNext = () => {
        setFade(false);
        setTimeout(() => {
            setCurrentIndex(
                currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1
            );
            setFade(true);
            resetAutoplay();
        }, 1000);
    };

    return (
        <section className="bg-gray-100 py-5 px-4 min-h-screen flex justify-center items-center">
            <div className="container mx-auto" ref={ref}>
                <motion.h6
                    className="mb-3 font-semibold text-lg md:text-xl"
                    initial={{ y: -50, opacity: 0 }}
                    animate={controls}
                    variants={{
                        visible: { y: 0, opacity: 1 },
                    }}
                    transition={{ duration: 0.5 }}
                >
                    Testimoni
                </motion.h6>
                <div className="flex justify-between">
                    <motion.h3
                        className="font-semibold text-lg sm:text-xl md:text-2xl text-blue-500"
                        initial={{ y: -50, opacity: 0 }}
                        animate={controls}
                        variants={{
                            visible: { y: 0, opacity: 1 },
                        }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                    >
                        Apa Kata Klien Kami
                    </motion.h3>
                    <div>
                        <button
                            className="bg-blue-500 hover:bg-blue-400 text-white py-2 px-4 rounded-none mr-2"
                            onClick={handlePrev}
                        >
                            <FaArrowLeft />
                        </button>
                        <button
                            className="bg-blue-500 hover:bg-blue-400 text-white py-2 px-4 rounded-none"
                            onClick={handleNext}
                        >
                            <FaArrowRight />
                        </button>
                    </div>
                </div>
                <div className="carousel">
                    {testimonials.map((testimonial, index) => (
                        <div
                            key={index}
                            className={`carousel-item inset-0 transition-opacity duration-1000 ease-in-out ${index === currentIndex
                                    ? fade
                                        ? "opacity-100 animate-slideLeft"
                                        : "opacity-0 animate-fadeOut"
                                    : "hidden"
                                }`}
                        >
                            <div className="grid grid-cols-1 md:grid-cols-2 items-start md:items-center justify-center">
                                <motion.div
                                    className="w-full"
                                    initial={{ x: -100, opacity: 0 }}
                                    animate={controls}
                                    variants={{
                                        visible: { x: 0, opacity: 1 },
                                    }}
                                    transition={{ duration: 0.5, delay: 0.4 }}
                                >
                                    <img
                                        className="object-contain h-[80vh] w-full"
                                        src={testimonial.image}
                                        alt={`${testimonial.name}`}
                                    />
                                </motion.div>
                                <motion.div
                                    className="p-0 md:p-5"
                                    initial={{ x: 100, opacity: 0 }}
                                    animate={controls}
                                    variants={{
                                        visible: { x: 0, opacity: 1 },
                                    }}
                                    transition={{ duration: 0.5, delay: 0.6 }}
                                >
                                    <div className="flex items-center py-2 text-xl md:text-2xl">
                                        <span className="text-yellow-500 flex gap-2">
                                            {[...Array(testimonial.rating)].map((star, i) => (
                                                <FaStar key={i} className="text-yellow-500" />
                                            ))}
                                        </span>
                                        <span className="ml-2">{testimonial.rating}.0</span>
                                    </div>
                                    <p className="mb-4">{testimonial.feedback}</p>
                                    <p className="text-blue-500 font-bold mb-0">
                                        {testimonial.name}
                                    </p>
                                    <p className="text-gray-500">{testimonial.role}</p>
                                </motion.div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TestimonialCarousel;