import React, { useEffect } from 'react';
import { BsTruck, BsCreditCard, BsHeadset } from 'react-icons/bs';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const servicesData = [
    {
        icon: <BsTruck size={40} className='text-blue-500' />,
        title: 'Gratis Ongkir',
        description: 'Gratis ongkir untuk pesanan di atas 1000k.',
        delay: 0
    },
    {
        icon: <BsCreditCard size={40} className='text-blue-500' />,
        title: 'Pembayaran Aman',
        description: 'Berbagai pilihan pembayaran yang aman.',
        delay: 0.2
    },
    {
        icon: <BsHeadset size={40} className='text-blue-500' />,
        title: '24x7 Support',
        description: 'Kami mendukung pesanan secara online setiap hari.',
        delay: 0.4
    }
];

export default function Services() {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: false });

    useEffect(() => {
        if (inView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 20 });
        }
    }, [controls, inView]);

    return (
        <div className='px-4'>
            <div className='container mx-auto grid grid-cols-1 md:grid-cols-3 gap-4 py-10'>
                {servicesData.map((service, index) => (
                    <motion.div 
                        key={index}
                        ref={ref}
                        className='col-span-1'
                        initial={{ opacity: 0, y: 20 }}
                        animate={controls}
                        transition={{ duration: 0.5, delay: service.delay }}
                    >
                        <div className='flex items-center gap-4'>
                            {service.icon}
                            <div>
                                <h1 className='text-xl font-semibold text-blue-500'>{service.title}</h1>
                                <p>{service.description}</p>
                            </div>
                        </div>
                    </motion.div>
                ))}
            </div>
        </div>
    );
};