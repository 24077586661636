import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import blog1 from '../../assets/blog/blog-1.jpg';
import blog2 from '../../assets/blog/blog-2.jpg';
import blog4 from '../../assets/blog/blog-4.jpg';

const testimonials = [
    {
        imgSrc: blog1,
        imgAlt: 'Image blog 1',
        title: 'Kacamata Stylish Terbaru',
        description: 'Dapatkan kacamata stylish dengan diskon besar!',
        note: 'Jangan lewatkan kesempatan ini!'
    },
    {
        imgSrc: blog4,
        imgAlt: 'Image blog 4',
        title: 'Belanja Softlens Berkualitas',
        description: 'Nikmati kenyamanan softlens berkualitas dengan diskon menarik!',
        note: 'Jangan lewatkan kesempatan ini!'
    },
    {
        imgSrc: blog2,
        imgAlt: 'Image blog 2',
        title: 'Sunglasses Eksklusif',
        description: 'Lindungi mata Anda dengan sunglasses eksklusif. Harga spesial menanti!',
        note: 'Jangan lewatkan kesempatan ini!'
    }
];

const Blogs = () => {
    const controls = useAnimation();
    const [ref, inView] = useInView({ triggerOnce: true });

    React.useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    const variants = {
        hidden: { opacity: 0, x: 100 },
        visible: { opacity: 1, x: 0, transition: { duration: 0.5 } }
    };

    return (
        <section className="bg-gray-100 py-8 px-4">
            <div className="container mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {testimonials.map((testimonial, index) => (
                        <motion.div
                            key={index}
                            className="bg-transparent border-0"
                            ref={ref} // Apply ref to each motion.div
                            initial="hidden"
                            animate={controls}
                            variants={variants}
                        >
                            <img
                                className="w-full object-cover h-64 rounded"
                                src={testimonial.imgSrc}
                                alt={testimonial.imgAlt}
                            />
                            <div className="py-2">
                                <h5 className="py-1 text-blue-500 text-xl">{testimonial.title}</h5>
                                <p className="font-semibold">{testimonial.description}</p>
                                <p className="text-gray-500">{testimonial.note}</p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Blogs;